import { Field, Formik } from 'formik'
import React from 'react'
import { Columns } from 'react-bulma-components'
import useFilters from '../../hooks/filters'
import { FiltersResourcesEnum, MissionsFilters } from '../../store/filters'
import { dateInputValuesToWeekDates, dateToDateInputValue } from '../../utils/date'
import AutoSubmit from '../form/utils/auto-submit'
import Protected from '../protected/protected'
import CheckboxField from '../form/fields/checkbox'
import DisplayedDaysFilterField from '../filters/fields/displayed-days'
import WorkersFilterField from '../filters/fields/workers'
import ServiceFilterField from '../filters/fields/service'
import OrganizationFilterField from '../filters/fields/organization'
import { OrganizationTypeEnum } from '../../api/organizations'
import JobTitleFilterField from '../filters/fields/job-title'
import WeekFilterField from '../filters/fields/week'
import FieldColumn from '../filters/field-column'
import IsCancelledFilterField from '../filters/fields/is-cancelled'
import IsPrebilledFilterField from '../filters/fields/is-prebilled'
import ContractModelFilterField from '../filters/fields/contract-model'
import OrganizationOptionProtected from '../protected/organization-option-protected'

interface MissionFiltersProps {
  showDayFilter?: boolean
  showSummaryMinimalModeFilter?: boolean
  disabledQueries?: boolean
}

const MissionsFilters: React.FunctionComponent<MissionFiltersProps> = ({
  showDayFilter,
  showSummaryMinimalModeFilter,
  disabledQueries,
}) => {
  const { setFilters, filters } = useFilters(FiltersResourcesEnum.missions)

  const queriesStaleTime = 1000 * 60 * 2 // 2 min

  return (
    <Formik
      initialValues={{
        week: dateToDateInputValue(filters.start ? new Date(filters.start) : new Date()),
        service: filters.service || [],
        interimAgency: filters.interimAgency || [],
        employer: filters.employer || [],
        jobTitle: filters.jobTitle || [],
        preBilling: filters.preBilling || [],
        isCancelled: filters.isCancelled,
        day: filters.displayDay,
        user: filters.user || [],
        summaryMinimalMode: Boolean(filters.summaryMinimalMode),
        contractModel: filters.contractModel || [],
      }}
      onSubmit={values => {
        let newFilters: MissionsFilters = { ...filters }
        if (values.week) {
          const dates = dateInputValuesToWeekDates(values.week)
          if (dates) newFilters = { ...newFilters, ...dates }
        }
        newFilters.service = values.service
        newFilters.interimAgency = values.interimAgency
        newFilters.employer = values.employer
        newFilters.jobTitle = values.jobTitle
        newFilters.summaryMinimalMode = values.summaryMinimalMode ? 'minimal' : undefined
        newFilters.preBilling = values.preBilling
        newFilters.isCancelled = values.isCancelled
        newFilters.user = values.user
        newFilters.contractModel = values.contractModel
        newFilters.displayDay =
          // We reset the filter is week is changed, if not, the view is stuck to displayDay even if we chose another week
          filters.start &&
          dateInputValuesToWeekDates(values.week).start.toString() === filters.start.toString()
            ? values.day
            : undefined

        setFilters(FiltersResourcesEnum.missions, newFilters)
      }}
    >
      {props => {
        return (
          <>
            <Columns mb="0">
              <WeekFilterField />
              {showDayFilter && (
                <DisplayedDaysFilterField start={filters.start} end={filters.end} />
              )}
              <Protected roles={['employerMember', 'interimAgencyMember']}>
                {showSummaryMinimalModeFilter && (
                  <FieldColumn>
                    <Field
                      label="Mode compact"
                      name="summaryMinimalMode"
                      component={CheckboxField}
                      data-test="summaryMinimalModeCheckbox"
                    />
                  </FieldColumn>
                )}
              </Protected>
            </Columns>

            <Columns mb="0">
              <Protected roles={['employerMember', 'interimAgencyMember']}>
                <WorkersFilterField disabledQueries={disabledQueries} />
                <Protected roles={['employerMember']}>
                  <ServiceFilterField
                    disabledQueries={disabledQueries}
                    queriesStaleTime={queriesStaleTime}
                    employerIds={filters.employer}
                  />
                </Protected>
                <Protected roles={['interimAgencyMember']}>
                  <ServiceFilterField
                    disabledQueries={Boolean(disabledQueries || !filters.employer?.[0])}
                    queriesStaleTime={queriesStaleTime}
                    employerIds={filters.employer}
                  />
                </Protected>
              </Protected>
              <Protected roles={['employerMember']}>
                <OrganizationOptionProtected
                  options={[{ organizationType: 'employer', option: 'biPart' }]}
                >
                  <ContractModelFilterField />
                </OrganizationOptionProtected>
                <OrganizationFilterField
                  disabledQueries={disabledQueries}
                  queriesStaleTime={queriesStaleTime}
                  type={OrganizationTypeEnum.interimAgency}
                />
              </Protected>
              <Protected roles={['interimAgencyMember']}>
                <OrganizationFilterField
                  disabledQueries={disabledQueries}
                  queriesStaleTime={queriesStaleTime}
                  type={OrganizationTypeEnum.employer}
                />
              </Protected>
              <Protected roles={['employerMember']}>
                <JobTitleFilterField
                  disabledQueries={disabledQueries}
                  queriesStaleTime={queriesStaleTime}
                  employerIds={filters.employer}
                />
              </Protected>
              <Protected roles={['employerMember', 'interimAgencyMember']}>
                <IsPrebilledFilterField />
                <IsCancelledFilterField />
              </Protected>
            </Columns>
            <AutoSubmit values={props.values} submitForm={props.submitForm} />
          </>
        )
      }}
    </Formik>
  )
}

export default MissionsFilters
