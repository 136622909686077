import React from 'react'
import { Cell, Column } from 'react-table'
import { MissionsFilters } from '../../store/filters'
import StandardCell from '../table/standard-cell'
import { Mission, MissionWithSummaries, MissionWithSummary } from '../../api/missions'
import { Element, Icon } from 'react-bulma-components'
import { Link } from 'react-router-dom'
import { formatCost } from '../../utils/finance'
import {
  MissionAbandonedMessage,
  MissionIsCancelledMessage,
  MissionIsPreBilledMessage,
  MissionIsValidatedMessage,
  MissionWeekIsValidatedMessage,
} from '../missions/mission-messages'
import {
  allDaysOfCurrentWeek,
  allDaysOfInterval,
  formatDay,
  formatDuration,
  getWeek,
  getWeekYear,
} from '../../utils/date'
import {
  SummaryValue,
  WorkPeriodSummaryCell,
  workPeriodSummaryHeaders,
} from '../table/summary-cell'
import { missionSummaryTotalsHeaders } from '../table/summary-cell'
import { isAuthorizedFor, isProtectedFor } from '../protected/protected'
import { MissionSummaryCell } from '../table/summary-cell'
import { missionSummaryCostsHeaders } from '../table/summary-cell'
import Table from '../table'
import useStore from '../../store'
import { EyeIcon } from '@heroicons/react/outline'
import { InfoIcon } from '../icons'
import { sortBy, upperFirst } from 'lodash'
import { TrimTextIfLongerThan } from '../../utils/texts'
import { getUserLabel } from '../../utils/users'
import { EmployerRule } from '../../api/employer-rules'
import { WeekSummary } from '../../../../backend/src/services/resources/missions/missions.summary.service.types'
import { useUpdateMutation } from '../../queries/work-periods'
import { WorkPeriodWithMission } from '../../api/work-periods'

interface SummaryTableProps {
  missions: MissionWithSummaries[]
  summaryMinimalMode?: MissionsFilters['summaryMinimalMode']
  displayDay?: Date
  start?: Date
  end?: Date
  hideActions?: boolean
  employerRules: WeekSummary['employerRules']
}

const SummaryTable: React.FC<SummaryTableProps> = ({
  missions,
  summaryMinimalMode,
  displayDay,
  start,
  end,
  hideActions,
  employerRules,
}) => {
  const tableColumns = useColumns(
    summaryMinimalMode,
    displayDay,
    start,
    end,
    hideActions,
    employerRules,
  )

  return (
    <Table
      columns={tableColumns}
      data={sortBy(missions, ['user._id', 'interimAgency._id'])}
      noDataMessage="Aucune mission à afficher"
      className="ml-2"
    />
  )
}

const useColumns = (
  summaryMinimalMode?: MissionsFilters['summaryMinimalMode'],
  displayDay?: Date,
  start?: Date,
  end?: Date,
  hideActions?: boolean,
  employerRules?: SummaryTableProps['employerRules'],
): Column[] => {
  const currentUserRole = useStore(state => state.session.currentUserRole)
  const workPeriodUpdateMutation = useUpdateMutation()

  const totalsHeaders = [
    ...missionSummaryTotalsHeaders,
    ...buildEmployerRulesAsTableHeaders(employerRules || [], 'totals', false, summaryMinimalMode),
  ]

  const costsHeaders = [
    missionSummaryCostsHeaders[0],
    missionSummaryCostsHeaders[1],
    ...buildEmployerRulesAsTableHeaders(employerRules || [], 'costs', true, summaryMinimalMode),
    isAuthorizedFor(['interimAgencyMember']) &&
      !employerRules?.[0] &&
      !summaryMinimalMode &&
      ({
        key: `costs.employerDataTotal`,
        label: 'Variables',
        display: formatCost,
      } as SummaryValue),
    missionSummaryCostsHeaders[2],
  ].filter(v => v)

  const workPeriodsHeaders = [
    ...workPeriodSummaryHeaders.slice(0, -4),
    ...buildEmployerRulesAsTableHeaders(employerRules || [], 'days', false, summaryMinimalMode),
    ...workPeriodSummaryHeaders.slice(
      workPeriodSummaryHeaders.length - 4,
      workPeriodSummaryHeaders.length,
    ),
  ]

  return React.useMemo(() => {
    const removeIfMinimalSummaryMode = (column: Column) => {
      if (summaryMinimalMode !== 'minimal') return true
      // We are in minimal Summary Mode
      return !(
        column.Header === 'Jour' ||
        column.Header === 'Pause' ||
        column.Header === 'Nuit' ||
        column.Header === 'Supp' ||
        column.Header === 'Service' ||
        column.Header === 'Poste' ||
        column.id === 'missionLink' ||
        column.Header === 'Interruption' ||
        column.accessor === 'isValidated' ||
        column.accessor === 'isCancelled' ||
        column.accessor === 'isAbandoned' ||
        employerRules?.some(r => r.name === column.Header)
      )
    }

    const isSameUserThanPreviousRow = (data: MissionWithSummary[], rowIndex: number): boolean =>
      data[rowIndex - 1]?.user?._id === data[rowIndex]?.user?._id &&
      data[rowIndex - 1]?.interimAgency?._id === data[rowIndex]?.interimAgency?._id
    const countRowsForUser = (missions: any[], userId: string, interimAgencyId: string): number =>
      missions.filter(
        mission => mission.user._id === userId && mission.interimAgency?._id === interimAgencyId,
      ).length || 0

    return [
      {
        Header: ' ',
        className: 'sticky has-background-white',
        columns: [
          {
            Header: 'Nom',
            accessor: 'user.firstName',
            rowSpan: 2,
            className: 'sticky table-section-start table-section-end-sticky has-background-white',
            isHidden: (cell: Cell<MissionWithSummary>, data: MissionWithSummary[]) =>
              isSameUserThanPreviousRow(data, cell.row.index),
            getRowSpan: (cell: Cell<MissionWithSummary>, data: MissionWithSummary[]) =>
              countRowsForUser(
                data,
                cell.row.original.user._id as string,
                cell.row.original.interimAgency?._id as string,
              ),

            Cell: (data: any) => {
              const mission: Mission = data.cell.row.original
              if (isSameUserThanPreviousRow(data.data, data.row.index)) return null

              return (
                <StandardCell>
                  {!isSameUserThanPreviousRow(data.data, data.row.index) && (
                    <Element textWeight="bold">{getUserLabel(mission.user)}</Element>
                  )}
                </StandardCell>
              )
            },
          },
        ],
      },
      !displayDay && {
        Header: `Semaine`,
        columns: [
          {
            Header: (
              <MissionWeekIsValidatedMessage
                mission={{ weekIsValidated: true }}
                type="icon"
                small
                iconColor="dark"
              />
            ),
            accessor: 'weekIsValidated',
            isHidden: (cell: Cell<MissionWithSummary>, data: MissionWithSummary[]) =>
              isSameUserThanPreviousRow(data, cell.row.index),
            getRowSpan: (cell: Cell<MissionWithSummary>, data: MissionWithSummary[]) =>
              countRowsForUser(
                data,
                cell.row.original.user._id as string,
                cell.row.original.interimAgency?._id as string,
              ),
            Cell: (data: any) => {
              const mission: MissionWithSummaries = data.cell.row.original
              return (
                <MissionWeekIsValidatedMessage
                  mission={{ weekIsValidated: mission.weekSummary.weekIsValidated }}
                  type="icon"
                  small
                />
              )
            },
          },
          {
            Header: <MissionIsPreBilledMessage force type="icon" iconColor="dark" small />,
            accessor: 'preBilling',
            id: 'leftIsPreBilledLevel2',
            className: 'table-section-end',
            isHidden: (cell: Cell<MissionWithSummary>, data: MissionWithSummary[]) =>
              isSameUserThanPreviousRow(data, cell.row.index),
            getRowSpan: (cell: Cell<MissionWithSummary>, data: MissionWithSummary[]) =>
              countRowsForUser(
                data,
                cell.row.original.user._id as string,
                cell.row.original.interimAgency?._id as string,
              ),
            Cell: (data: any) => {
              const mission: Mission = data.cell.row.original
              return (
                <MissionIsPreBilledMessage
                  mission={mission}
                  type="icon"
                  small
                  week={getWeek(start)}
                  year={getWeekYear(start as Date)}
                />
              )
            },
          },
        ],
      },
      {
        Header: 'Mission',
        id: 'mission.details',
        columns: [
          {
            Header: currentUserRole === 'employerMember' ? 'Agence' : 'Client',
            accessor: currentUserRole === 'employerMember' ? 'interimAgency.name' : 'employer.name',
            className: 'table-section-start',
            Cell: (data: any) => {
              const mission: Mission = data.cell.row.original
              return (
                <StandardCell>
                  <Element renderAs={'span'} textColor="dark" textWeight="semibold">
                    {currentUserRole === 'employerMember'
                      ? mission.interimAgency?.name ?? '/'
                      : mission.employer.name}
                  </Element>
                </StandardCell>
              )
            },
          },
          {
            Header: 'Service',
            accessor: 'service',
            Cell: (data: any) => {
              const mission: Mission = data.cell.row.original
              return (
                <StandardCell>
                  <Element renderAs={'span'} textColor="dark" textWeight="semibold">
                    <TrimTextIfLongerThan ifIsLongerThan={10}>
                      {upperFirst(mission.service.name)}
                    </TrimTextIfLongerThan>
                  </Element>
                </StandardCell>
              )
            },
          },
          {
            Header: 'Poste',
            accessor: 'jobTitle.name',
            Cell: (data: any) => {
              const mission: Mission = data.cell.row.original
              return (
                <StandardCell>
                  <Element renderAs={'span'} textColor="dark" textWeight="semibold">
                    <TrimTextIfLongerThan ifIsLongerThan={16}>
                      {upperFirst(mission.jobTitle.name)}
                    </TrimTextIfLongerThan>
                  </Element>
                </StandardCell>
              )
            },
          },
          {
            Header: (
              <Icon data-tooltip={'Voir la mission'} className="has-tooltip-left" size="small">
                <EyeIcon />
              </Icon>
            ),
            id: 'missionLink',
            accessor: 'link',
            Cell: (data: any) => {
              const mission: Mission = data.cell.row.original
              return (
                <Element
                  renderAs={Link}
                  to={`/missions/${mission._id}`}
                  textWeight="bold"
                  data-test="go-to-mission-details-action"
                >
                  <Icon data-tooltip={'Voir la mission'} className="has-tooltip-left" size="small">
                    <EyeIcon />
                  </Icon>
                </Element>
              )
            },
          },
          {
            Header: (
              <MissionIsValidatedMessage
                mission={{ isValidated: true }}
                type="icon"
                small
                iconColor="dark"
              />
            ),
            accessor: 'isValidated',
            Cell: (data: any) => {
              const mission: Mission = data.cell.row.original
              return (
                <MissionIsValidatedMessage
                  mission={mission}
                  type="icon"
                  small
                  iconColor={'primary'}
                />
              )
            },
          },
          {
            Header: (
              <MissionAbandonedMessage
                mission={{ isAbandoned: true }}
                type="icon"
                small
                iconColor="dark"
              />
            ),
            accessor: 'isAbandoned',
            Cell: (data: any) => {
              const mission: Mission = data.cell.row.original
              return <MissionAbandonedMessage mission={mission} type="icon" small />
            },
          },
          {
            Header: (
              <MissionIsCancelledMessage
                mission={{ isCancelled: true }}
                type="icon"
                small
                iconColor="dark"
              />
            ),
            accessor: 'isCancelled',
            Cell: (data: any) => {
              const mission: Mission = data.cell.row.original
              return <MissionIsCancelledMessage mission={mission} type="icon" small />
            },
          },
        ]
          .filter(removeIfMinimalSummaryMode)
          .filter(
            column =>
              !(
                column.accessor.match('actions') &&
                (isProtectedFor(['employerMember']) || hideActions)
              ),
          ),
      },
      ...(displayDay
        ? [displayDay]
        : start && end
        ? allDaysOfInterval(new Date(start), new Date(end))
        : allDaysOfCurrentWeek
      ).map((day: Date, index) => ({
        Header: formatDay(day),
        accessor: 'day' + index,
        columns: workPeriodsHeaders
          .map(workPeriodSummaryHeader => ({
            Header: workPeriodSummaryHeader.label,
            id: day + workPeriodSummaryHeader.key,
            className: (() => {
              const classNames = []
              if (workPeriodSummaryHeader.key === 'start.counted')
                classNames.push('table-section-start')

              if (displayDay && workPeriodSummaryHeader.key === 'validation')
                classNames.push('table-section-end')
              return classNames.join(' ')
            })(),
            Cell: (data: any) => {
              return (
                <WorkPeriodSummaryCell
                  data={data}
                  day={day}
                  workPeriodSummaryHeader={workPeriodSummaryHeader}
                  validationAction={(workPeriod: WorkPeriodWithMission) => {
                    workPeriodUpdateMutation.mutate({
                      missionId: workPeriod.mission._id,
                      workPeriodId: workPeriod._id,
                      isValidated: !workPeriod.isValidated,
                    })
                  }}
                />
              )
            },
          }))
          .filter(
            column =>
              !(column.Header === 'Valider' && (isProtectedFor(['employerMember']) || hideActions)),
          )
          .filter(removeIfMinimalSummaryMode),
      })),
      !displayDay && {
        Header: () => (
          <Element display="flex">
            Totaux
            {!hideActions && (
              <Icon
                size="small"
                color="primary"
                data-tooltip="Ce total est basé sur les filtres utilisés. Il peut ne pas être représentatif du total complet de la semaine."
                className="has-tooltip-multiline has-tooltip-bottom"
              >
                <InfoIcon />
              </Icon>
            )}
          </Element>
        ),
        id: 'Totaux',
        columns: totalsHeaders
          .map((missionSummaryTotalHeader, index) => {
            return {
              Header: missionSummaryTotalHeader.label,
              id: 'totals.' + missionSummaryTotalHeader.key,
              className: (() => {
                const classNames = []
                if (index === 0) classNames.push('table-section-start')
                if (index === totalsHeaders.length - 1) classNames.push('table-section-end')
                return classNames.join(' ')
              })(),
              isHidden: (cell: Cell<MissionWithSummary>, data: MissionWithSummary[]) =>
                isSameUserThanPreviousRow(data, cell.row.index),
              getRowSpan: (cell: Cell<MissionWithSummary>, data: MissionWithSummary[]) =>
                countRowsForUser(
                  data,
                  cell.row.original.user._id as string,
                  cell.row.original.interimAgency?._id as string,
                ),
              Cell: (data: any) => (
                <MissionSummaryCell data={data} missionSummaryHeader={missionSummaryTotalHeader} />
              ),
            }
          })
          .filter(removeIfMinimalSummaryMode),
      },
      !displayDay && {
        Header: () => (
          <Element display="flex">
            Coûts
            {!hideActions && (
              <Icon
                size="small"
                color="primary"
                data-tooltip="Ce total est basé sur les filtres utilisés. Il peut ne pas être représentatif du total complet de la semaine."
                className="has-tooltip-multiline has-tooltip-bottom"
              >
                <InfoIcon />
              </Icon>
            )}
          </Element>
        ),
        id: 'Coûts',
        columns: costsHeaders
          .map(missionSummaryCostHeader => {
            return {
              Header: missionSummaryCostHeader?.label,
              id: 'costs.' + missionSummaryCostHeader?.key,
              className: (() => {
                const classNames = []
                if (missionSummaryCostHeader?.key === 'costs.total')
                  classNames.push('table-section-end')
                return classNames.join(' ')
              })(),
              isHidden: (cell: Cell<MissionWithSummary>, data: MissionWithSummary[]) =>
                isSameUserThanPreviousRow(data, cell.row.index),
              getRowSpan: (cell: Cell<MissionWithSummary>, data: MissionWithSummary[]) =>
                countRowsForUser(
                  data,
                  cell.row.original.user._id as string,
                  cell.row.original.interimAgency?._id as string,
                ),
              Cell: (data: any) => (
                <MissionSummaryCell
                  data={data}
                  missionSummaryHeader={missionSummaryCostHeader as SummaryValue}
                />
              ),
            }
          })
          .filter(removeIfMinimalSummaryMode),
      },
    ].filter(c => c) as Column[]
  }, [displayDay, start, end, summaryMinimalMode, employerRules])
}

export default SummaryTable

const buildEmployerRulesAsTableHeaders = (
  employerRules: SummaryTableProps['employerRules'],
  display: keyof EmployerRule['display']['summary'],
  isCost?: boolean,
  summaryMinimalMode?: MissionsFilters['summaryMinimalMode'],
): SummaryValue[] => {
  if (summaryMinimalMode) return []
  return (employerRules || [])
    .filter(employerRule => employerRule.display.summary[display])
    .map(e => ({
      key: isCost
        ? `employerData.costs["${e.name}"]`
        : e.kind === 'goal'
        ? `employerData.goals["${e.name}"]`
        : `employerData.durations["${e.name}"]`,
      label: e.shortName,
      display: isCost ? formatCost : e.kind === 'goal' ? (v: number) => v : formatDuration,
      emptyValue: e.kind === 'goal' ? '0' : undefined,
    }))
}
