# Quoi de neuf TeamTim ?

Ce document reprend de façon chronologique les mises à jour récentes de la plateforme TeamTim

---

## 14 Février 2025

##### Adaptations au Récapitulatif pour les ETT et EU
  - En Mode Compact plusieurs missions d'une même personne peuvent être compactées sur une même ligne
  - Désactiver le Mode Compact affiche le détail des missions, une par ligne 
  - Valider une Journée de Travail n'exige plus de double confirmation
  - L'indicateur de statut Semaine Validée est plus visible
  - L'indicateur de statut Semaine Préfacturée est plus visible
  - Le bouton pour dévalider une Journée de Travail est plus discret
  - Suppression du raccourci pour Interrompre une Mission
  - Suppression du raccourci pour Valider une Semaine
  - Filtrer par Journée n'affiche plus de lignes vides

---

## 13 Février 2025

  - Correction d'un bug lié à l'application rétro-active de Règles de Pointages sur des Journées de Travail existantes

---

## 11 Février 2025

  - Une EU peut maintenant configurer ses Services via les Paramètres  

---

## 10 Février 2025

  - Amélioration du filtre permettant d'afficher les Demandes d'Intérimaires expirées  

---

## 7 Février 2025

  - Correction d'un bug d'affichage sur des oridnateurs utilisant le mode sombre par défaut
  - Correction d'un bug lié au paramétrage des heures de jour et de nuit pour les EU
  - Correction d'un bug d'affichage de l'indicateur de statut de validation d'une Journée de Travail

---

## 5 Février 2025

  - Les bornes de scan se mettent à jour de façon automatique sans validation
  - Correction d'un bug empêchant l'envoi de Demandes impliquant des Intérimaires n'ayant pas complété leur profil

---