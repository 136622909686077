import React from 'react'
import FieldColumn from '../field-column'
import { Field } from 'formik'
import MultiSelectField from '../../form/fields/multi-select'

/**
 * Composant de filtre pour le type de contrat de mission
 * Permet de filtrer entre contrats bipartites et tripartites
 */
const ContractModelFilterField: React.FC = () => {
  return (
    <FieldColumn>
      <Field
        label="Type de contrat"
        name="contractModel"
        component={MultiSelectField}
        options={[
          {
            label: 'Salariés',
            value: 'twoParties',
          },
          {
            label: 'Intérimaires',
            value: 'threeParties',
          },
        ]}
      />
    </FieldColumn>
  )
}

export default ContractModelFilterField
